import styled from 'styled-components';

import breakpoints from '~constants/breakpoints';
import margins from '~constants/margins';

export const PartnersRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    row-gap: 35px;
    column-gap: 25px;
    z-index: 2;
`;

export const PartnersContainer = styled.div`
    ${margins};

    margin-bottom: 36px !important;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    gap: 80px;

    padding: 0 10px;

    ${PartnersRow} {
        &:nth-child(1) {
            height: 45.5px;
        }
    }

    ${breakpoints.aboveTablet} {
        padding: 0 50px;
    }

    ${breakpoints.aboveDesktop} {
        padding: 0 100px;
    }
`;

export const RowContainer = styled.div`
    height: 45.5px;
    display: flex;
    align-items: center;
    gap: 204px;
    margin-right: 204px;
    ${breakpoints.tablet} {
        gap: 96px;
        margin-right: 96px;
    }
`;
