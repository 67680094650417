import styled, {css} from 'styled-components';

const animationEntrySetup = css`
    &:first-child {
        // Start mid way to hide first entry and and alternate with second car
        animation-delay: calc((var(--animationDuration) / 2) * -1);
    }

    &:last-child {
        animation-delay: 0;
    }
`;

export const Car = styled.div`
    @keyframes slideRight {
        0% {
            transform: translate3d(0%, 0, 0);
        }
        100% {
            transform: translate3d(200%, 0, 0);
        }
    }

    @keyframes slideLeft {
        0% {
            transform: translate3d(0, 0, 0);
        }
        100% {
            transform: translate3d(-200%, 0, 0);
        }
    }

    position: absolute;
    height: 100%;
    display: flex;
    will-change: transform;
`;

type LaneProps = {
    direction: 'left' | 'right';
    animationDuration: number;
};

export const Lane = styled.div<LaneProps>`
    width: 100%;
    position: relative;
    --animationDuration: ${({animationDuration}) => `${animationDuration * 2}ms`};

    ${({direction}) =>
        direction === 'left'
            ? css`
                  ${Car} {
                      left: 100%;
                      animation: slideLeft var(--animationDuration) linear both
                          infinite;
                      ${animationEntrySetup};
                  }
              `
            : css`
                  ${Car} {
                      right: 100%;
                      animation: slideRight var(--animationDuration) linear both
                          infinite;
                      ${animationEntrySetup};
                  }
              `}

    &:hover {
        * {
            animation-play-state: paused !important;
        }
    }
`;
