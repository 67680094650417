import React from 'react';

import {getImageData} from '~utils/imageData';
import CustomLink from '~components/atoms/CustomLink';
import {
    PartnersSection,
    PartnersSectionPartners,
} from '~interfaces/graphql/PartnersSection';
import InfiniteAnimatedSlider from '~components/atoms/InfiniteAnimatedSlider';

import {PartnersContainer, PartnersRow, RowContainer} from './styles';

const Partners = ({partners, bottomLogo}: PartnersSection) => {
    return (
        <PartnersContainer>
            <PartnersRow>
                <InfiniteAnimatedSlider direction="left" pixelsPerSecond={50}>
                    <RowContainer>
                        {partners &&
                            (partners as PartnersSectionPartners[]).map(
                                ({url, icon, target}, index) =>
                                    url ? (
                                        <CustomLink
                                            href={url || ''}
                                            target={target}
                                            key={url}
                                        >
                                            {icon && <img {...getImageData(icon)} />}
                                        </CustomLink>
                                    ) : (
                                        <div key={index}>
                                            {icon && <img {...getImageData(icon)} />}
                                        </div>
                                    ),
                            )}
                    </RowContainer>
                </InfiniteAnimatedSlider>
            </PartnersRow>
            <PartnersRow>
                {bottomLogo && (
                    <CustomLink
                        href={bottomLogo.url || ''}
                        target={bottomLogo.target || '_blank'}
                    >
                        {bottomLogo.icon && (
                            <img {...getImageData(bottomLogo.icon)} />
                        )}
                    </CustomLink>
                )}
            </PartnersRow>
        </PartnersContainer>
    );
};

export default Partners;
