import {memo, ReactNode, useMemo, useRef, useState} from 'react';

import useLayoutEffect from '~hooks/useLayoutEffect';

import {Car, Lane} from './styles';

type InfiniteAnimatedSliderProps = {
    children: ReactNode;
    direction: 'left' | 'right';
    pixelsPerSecond: number;
};

const InfiniteAnimatedSlider = ({
    children,
    direction,
    pixelsPerSecond,
}: InfiniteAnimatedSliderProps) => {
    const carRef = useRef<HTMLDivElement>(null);

    const [animationDuration, setAnimationDuration] = useState(0);

    const duplicatedChildren = useMemo(
        () => (
            <>
                {/* Make sure to fill screen width */}
                {children}
                {children}
                {children}
                {children}
                {children}
                {children}
            </>
        ),
        [children],
    );

    useLayoutEffect(() => {
        if (carRef.current) {
            setAnimationDuration(
                Math.round((carRef.current.offsetWidth / pixelsPerSecond) * 1000),
            );
        }
    }, [pixelsPerSecond, duplicatedChildren]);

    return (
        <Lane direction={direction} animationDuration={animationDuration}>
            <Car ref={carRef}>{duplicatedChildren}</Car>
            <Car>{duplicatedChildren}</Car>
        </Lane>
    );
};

export default memo(InfiniteAnimatedSlider);
